<template>
  <b-row>
    <b-col sm="10" md="12" lg="10" xl="10">
      <card>
        <b-container fluid>
          <b-row>
            <b-col sm="6" md="4" lg="4" xl="4" v-for="(integration, index) in integrations"
            :key="`integration_${index}`" class="mb-4"
            >
            <div class="img-block d-flex w-100 align-items-center overflow-hidden active-element"
              @click="$emit('select-integration', integration.componentName)"
              >
                <img :src="integration.logo" class="w-100"/>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </card>
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: {
    integrations: Array,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';
  .img-block{
    background: #fff;
    border: 1px solid $gray-300;
    transition: all .35s linear;
    aspect-ratio:10/5;
    &:hover{
      box-shadow: 3px 3px 7px $gray-400;
    }
    img {
      //height: 100%;
      //object-fit: cover;
    }
}
</style>
