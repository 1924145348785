<template>
  <div class="pl-3">
    <div v-if="!connected" class="mb-4">
    <base-switch
    v-model="on"
    onText="On"
    offText="Off"
    >
    </base-switch>
    </div>
    <ValidationObserver v-slot="{ invalid }" ref="validator">
      <template v-if="!connected">
        <base-input
          v-model="domain"
          rules="customRequired:Your Fresh Team domain is required"
          name="Domain"
          label="Enter your Fresh Team domain name"
          :disabled="!on"
          inputAdd=".freshteam.com"
        >
        <template v-slot:bottomLabel>
          <div class="d-flex align-items-center">
            <i class="mr-2 fa fa-info-circle"></i>
            <span class="pre-small-text">
              If your Freshteam's account url is "acmecorp.freshteam.com", "acmecorp" is your domain name '
            </span>
          </div>
        </template>
        </base-input>
        <base-input
          type="text"
          v-model="apiKey"
          rules="customRequired"
          name="API key"
          label="Enter your API key"
          :disabled="!on"
        >
        <template v-slot:bottomLabel>
          <div class="d-flex align-items-center">
            <i class="mr-2 fa fa-info-circle"></i>
            <div class="pre-small-text">
              <span>
                Click on your profile icon in the top-right corner in Freshteam to get your API key </span>
              <a :href="helpUrl" target="blank"><u>Not able to find?</u></a>
            </div>
          </div>
        </template>
        </base-input>
      </template>
      <connection-buttons
      :connected="connected"
      :disabled="!on || invalid"
      @connect="connect"
      @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';
import {supportUrl} from '@/store/userConstants';

export default {
  components: {
    ConnectionButtons,
  },
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      apiKey: '',
      domain: '',
      helpUrl: `${supportUrl}5864877-how-to-integrate-freshteam-ats-with-rectxt`,
    };
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsIntegrationEnabled: this.on,
          atsType: this.type,
          atsApiCredentials: {
            apiKey: this.apiKey,
            domain: this.domain,
          },
          // atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
