<template>
<b-row>
  <b-col md="8">
    <card>
      <template>
        <div>
          <div v-if="!enabled" class="active-element d-flex align-items-center navbar-light navbar-text mb-3"
          @click="$emit('back')"
          ><i class="ni ni-bold-left"></i><span class="font-600">Back</span></div>
          <b-container fluid>
            <b-row>
              <b-col sm="3">
                <div class="mb-2 d-flex justify-content-end">
                <b-badge v-if="enabled" variant="primary">Connected</b-badge>
                </div>
                <div><img :src="logo" class="w-100 logo-border"/></div>
                <a
                v-if="showInstructions" class="text-center w-100 d-inline-block mt-3 font-600"
                :href="help" target="blank">
                  <u>Instructions</u>
                </a>
              </b-col>
              <b-col sm="9"><slot></slot></b-col>
            </b-row>
          </b-container>
        </div>
      </template>
    </card>
  </b-col>
</b-row>
</template>
<script>
export default {
  props: {
    name: String,
    logo: String,
    enabled: Boolean,
    help: String,
    type: String,
  },
  computed: {
    showInstructions: function() {
      return !!this.help;
    },
    // connected: function() {
    //   return ['jobadder', 'greenhouse'].includes(this.type);
    // },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/argon.scss';
.logo-border {
  border: 1px solid $gray-300;
}
</style>

