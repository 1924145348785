<template>
  <dashboard-page-wrapper :title="'Integrations'">
    <ZoomCenterTransition group>
      <template v-if="!selectedIntegration">
        <integrations-list
        key="integrationList"
        :integrations="integrationsList"
        @select-integration="selectIntegrationHandler"
        />
      </template>
      <template v-else>
        <integration-screen-wrapper
        :name="selectedIntegration.name"
        :type="selectedIntegration.type"
        :logo="selectedIntegration.logo"
        :help="selectedIntegration.help"
        :enabled="atsIntegrationEnabled"
        @back="selectedIntegration = null"
        key="integrationScreenWrapper"
        >
          <component
          :is="selectedIntegration.componentName"
          :type="selectedIntegration.type"
          :connected="atsIntegrationEnabled"
          @test-connection="testConnectionHandler"
          @disconnect="disconnect"
          @oauth-integration="oauthIntegration"
          />
        </integration-screen-wrapper>
      </template>
    </ZoomCenterTransition>
  </dashboard-page-wrapper>
</template>
<script>
import {ZoomCenterTransition} from 'vue2-transitions';
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import IntegrationsList from './IntegrationsList.vue';
import IntegrationScreenWrapper from './IntegrationScreenWrapper.vue';
import CrelateTalent from './screens/CrelateTalent.vue';
import Greenhouse from './screens/Greenhouse.vue';
import SmartRecruiters from './screens/SmartRecruiters.vue';
import Jobadder from './screens/Jobadder.vue';
import Lever from './screens/Lever.vue';
import FreshTeam from './screens/FreshTeam';
import Recruitee from './screens/Recruitee.vue';
import Comeet from './screens/Comeet.vue';
import Ashby from './screens/Ashby.vue';
import PinPoint from './screens/PinPoint.vue';
import {mapActions, mapGetters} from 'vuex';
import {atsIntegrationsList} from '@/store/userConstants';
import {
  ATS_INTEGRATION_CONNECTION_REQUEST,
  DELETE_ATS_INTEGRATION_REQUEST,
  FETCH_ATS_INTEGRATION_REQUEST,
  ATS_INTEGRATION_OAUTH_REQUEST,
} from '@/store/storeActions';

export default {
  components: {
    DashboardPageWrapper,
    IntegrationsList,
    IntegrationScreenWrapper,
    CrelateTalent,
    Greenhouse,
    SmartRecruiters,
    Jobadder,
    Lever,
    FreshTeam,
    ZoomCenterTransition,
    Recruitee,
    Comeet,
    Ashby,
    PinPoint,
  },
  data: function() {
    return {
      integrationsList: atsIntegrationsList,
      selectedIntegration: null,
    };
  },
  computed: {
    ...mapGetters([
      'atsIntegration',
      'atsIntegrationEnabled',
      'integrationLoaded',
      'userCalendarShortcut',
      'calendarShortcuts',
      'calendarLoaded',
      'userEmail',
    ]),
  },
  watch: {
    atsIntegrationEnabled: {
      handler: function(val) {
        this.selectedIntegration = val ?
        this.integrationsList.find((integration) => integration.type === this.atsIntegration.atsType) : null;
      },
      immediate: true,
    },
  },
  mounted() {
    !this.integrationLoaded && this.FETCH_ATS_INTEGRATION_REQUEST();
  },
  methods: {
    ...mapActions([
      FETCH_ATS_INTEGRATION_REQUEST,
      ATS_INTEGRATION_CONNECTION_REQUEST,
      DELETE_ATS_INTEGRATION_REQUEST,
      ATS_INTEGRATION_OAUTH_REQUEST,
    ]),
    selectIntegrationHandler: function(componentName) {
      this.selectedIntegration =
      this.integrationsList.find((integration) => integration.componentName === componentName);
    },
    testConnectionHandler: function({payload}) {
      this.ATS_INTEGRATION_CONNECTION_REQUEST({payload});
    },
    disconnect() {
      this.DELETE_ATS_INTEGRATION_REQUEST();
    },
    oauthIntegration(type) {
      this.ATS_INTEGRATION_OAUTH_REQUEST({type});
    },
  },
};
</script>
