<template>
  <div>
    <div v-if="connected" class="d-flex justify-content-start mt-5">
      <base-button
        type="outline-primary"
        @click="openModal"
      >Disconnect
      </base-button>
    </div>
    <div v-else class="d-flex justify-content-end mt-5">
      <base-button
        type="primary"
        :disabled="disabled"
        @click="$emit('connect')"
      >Connect
      </base-button>
    </div>
    <confirmation-modal
      ref="disconnectionConfirmationModal"
      header="Are you sure?"
      question="This will disconnect the integration for all team members.
      Are you sure you want to disconnect this integration for everyone on your team?"
      confirmBtnText="Disconnect"
      cancelBtnText="Cancel"
      confirmBtnType="danger"
      @on-accept="$emit('disconnect')"
    />
  </div>
</template>

<script>
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';

export default {
  components: {ConfirmationModal},
  props: {
    connected: Boolean,
    disabled: Boolean,
  },
  methods: {
    openModal() {
      this.$refs.disconnectionConfirmationModal.openModal();
    },
  },
};
</script>
