<template>
  <div class="pl-3">
    <div v-if="!connected" class="mb-4">
    <base-switch
    v-model="on"
    onText="On"
    offText="Off"
    >
    </base-switch>
    </div>
    <ValidationObserver v-slot="{ invalid }" ref="validator">
      <template v-if="!connected">
        <base-input
          v-model="email"
          rules="email|customRequired:Your Greenhouse email is required"
          name="email"
          label="Enter your Greenhouse email"
          :disabled="!on"
        >
        </base-input>
        <base-input
          type="text"
          v-model="apiKey"
          rules="customRequired"
          name="API key"
          label="Enter your API key"
          :disabled="!on"
        >
        </base-input>
      </template>
      <connection-buttons
      :connected="connected"
      :disabled="!on || invalid"
      @connect="connect"
      @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';

export default {
  components: {
    ConnectionButtons,
  },
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      apiKey: '',
      email: '',
    };
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsIntegrationEnabled: this.on,
          atsType: this.type,
          atsApiCredentials: {
            apiKey: this.apiKey,
            emailAddress: this.email,
          },
          atsIntegrationEnabled: true,
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
